<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card-text>
        <p class="text-center text-xl font-weight-semibold black--text mb-2">
          {{ t('confirmation_code.confirmation_code') }}
        </p>
        <p class="ma-0 pt-4 text-center">
          {{ t('confirmation_code.enter_code') }} {{ codeComputed }}
        </p>
      </v-card-text>

      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="verifySubmit"
        >
          <v-otp-input
            id="otp-input"
            v-model="accessCode"
            type="number"
            class="code-number ma-auto pl-3"
            :length="totalInput"
            @finish="verifySubmit"
          />

          <div class="d-flex justify-center align-center">
            <span class="mr-2">{{ t('confirmation_code.didnt_receive') }}</span>
            <v-btn
              class="pa-0 text-center no-bg-hover"
              style="
                text-transform: inherit;
                color: #FF3232;
                min-width: 60px;
              "
              text
              :ripple="false"
              @click="onResend"
            >
              {{ t('tooltip.resend') }}
            </v-btn>
          </div>

          <v-btn
            block
            color="primary"
            type="submit"
            rounded
            large
            class="mt-6 text-capitalize"
            :disabled="!isFormValid"
          >
            {{ t('tooltip.continue') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable no-lonely-if */
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, onMounted, getCurrentInstance, computed } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import usePhone from '@core/utils/usePhone'
import { sendCodeSMSUser, sendConfirmationEmailUser, checkVerificationSMSUser, checkVerificationEmailUser, registerUser, updateUser } from '@api'
import { useRouter } from '@core/utils'
import { error } from '@/@core/utils/toasted'
import store from '@/store'

export default {
  setup() {
    const { t } = useUtils()
    const { router } = useRouter()
    const { encrypt } = useCryptoJs()
    const { formatNumber } = usePhone()
    const { logData, currentLogData } = useLogData()
    const vm = getCurrentInstance().proxy

    const form = ref(null)
    const loginData = ref({
      code: '',
      fullPhone: '',
      type: '',
      loginRoute: 'LOGIN_PAGE',
      password: null,
    })
    const totalInput = ref(4)
    const completed = ref(false)
    const accessCode = ref('')

    const isThisYuoComputed = computed(() => loginData.value.typeIsThisYou && loginData.value.responseIsThisYou)

    const codeComputed = computed(() => {
      if (isThisYuoComputed.value) {
        return loginData.value.type === 'EMAIL' ? formatNumber(loginData.value.register.fullPhone) : loginData.value.register.email
      }

      return loginData.value.type === 'EMAIL' ? loginData.value.code : formatNumber(loginData.value.fullPhone)
    })

    const isFormValid = computed(() => {
      if (accessCode.value && accessCode.value.length === totalInput.value) return true

      return false
    })

    const verifySubmit = async () => {
      if (accessCode.value.length === totalInput.value) {
        let resp = null
        let body = null

        if (isThisYuoComputed.value) {
          body = {
            phone_number: loginData.value.register.fullPhone,
            sms_token: accessCode.value,
          }
          if (loginData.value.type === 'EMAIL') {
            resp = await checkVerificationSMSUser(body)
          } else {
            resp = await checkVerificationEmailUser(loginData.value.register.email, accessCode.value)
          }
        } else {
          body = {
            phone_number: loginData.value.fullPhone,
            sms_token: accessCode.value,
          }
          if (loginData.value.type === 'EMAIL') {
            resp = await checkVerificationEmailUser(loginData.value.code, accessCode.value)
          } else {
            resp = await checkVerificationSMSUser(body)
          }
        }

        if (resp.ok) {
          if (isThisYuoComputed.value) {
            let response = null
            if (loginData.value.responseIsThisYou === 'YES') {
              const bodyUpdate = {
                first_name: loginData.value.register.firstName,
                last_name: loginData.value.register.lastName,
                phone_number: loginData.value.register.fullPhone,
                email: loginData.value.register.email,
                password: loginData.value.register.password,
                username: loginData.value.username,
              }
              const encryptBodyUpdate = encrypt(bodyUpdate)
              response = await updateUser(encryptBodyUpdate)
            } else {
              const bodyRegister = {
                first_name: loginData.value.register.firstName,
                last_name: loginData.value.register.lastName,
                phone_number: loginData.value.register.fullPhone,
                email: loginData.value.register.email,
                password: loginData.value.register.password,
                validate_data: 'NO',
                type: loginData.value.type,
                username: loginData.value.username,
              }
              const encryptBodyRegister = encrypt(bodyRegister)
              response = await registerUser(encryptBodyRegister)
            }

            if (response.ok) {
              const loginBody = {
                code: loginData.value.code,
                type: loginData.value.type,
                join_game: loginData.value.joinGame,
                match_code: loginData.value.matchCode,
                login_route: 'LOGIN_PAGE',
                password: loginData.value.register.password,
              }
              const encryptBody = encrypt(loginBody)

              const userAbility = [
                {
                  action: 'manage',
                  subject: 'all',
                },
              ]
              vm.$ability.update(userAbility)

              // remove team
              store.dispatch('auth/loginUserAction', {
                data: encryptBody,
                redirect: logData.value.redirect,
                facility: logData.value.facility,
                field: logData.value.field,
                date: logData.value.date,
                time: logData.value.time,
                slot: logData.value.slot,
                pay: logData.value.pay,
                reservationCode: logData.value.reservationCode,
                showPay: logData.value.showPay,
                team: logData.value.team,
                payType: logData.value.payType,
              })
            } else {
              error(resp.message.text)
            }
          } else {
            router.push({ name: 'auth-create-account' })
          }
        } else {
          accessCode.value = ''
          error(resp.message.text)
        }
      }
    }

    const onChangeLogin = () => {
      router.go(-1)
    }

    const onResend = async () => {
      accessCode.value = ''
      let info = null
      let encryptBody = null

      if (isThisYuoComputed.value) {
        if (loginData.value.type === 'PHONE_NUMBER') {
          info = {
            email: loginData.value.register.email,
          }
          encryptBody = encrypt(info)
          await sendConfirmationEmailUser(encryptBody)
        } else {
          info = {
            phone_number: loginData.value.register.fullPhone,
          }
          encryptBody = encrypt(info)
          await sendCodeSMSUser(encryptBody)
        }
      } else {
        if (loginData.value.type === 'PHONE_NUMBER') {
          info = {
            phone_number: loginData.value.fullPhone,
          }
          encryptBody = encrypt(info)
          await sendCodeSMSUser(encryptBody)
        } else {
          info = {
            email: loginData.value.code,
          }
          encryptBody = encrypt(info)
          await sendConfirmationEmailUser(encryptBody)
        }
      }
    }

    onMounted(async () => {
      Object.assign(logData.value, currentLogData.value)
      logData.value.loginRoute = 'LOGIN_PAGE'
      loginData.value = logData.value
    })

    return {
      form,
      loginData,
      isFormValid,
      isThisYuoComputed,
      codeComputed,

      totalInput,
      completed,
      accessCode,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      verifySubmit,
      onChangeLogin,
      onResend,
      formatNumber,

      t,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
@import '@core/preset/preset/pages/auth-code.scss';
</style>
